
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useFilter } from '@/composables/useFilter'

import { useDeleteNote, useGetNotes } from '@/api/note'

import { getCorrespondingEntity } from '@/views/administration/utils'
import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { NoteOutput } from '@/api/types/note'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Notes',
  components: {
    CommonTableView,
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    AddEditNoteDialog: () => import('@/views/contractData/components/AddEditNoteDialog.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('administration.notes.table.col.title.entity'),
        value: 'entity',
        sortable: false,
      },
      {
        text: root.$t('administration.notes.table.col.title.entityId'),
        value: 'entityId',
        sortable: false,
      },
      {
        text: root.$t('administration.notes.table.col.title.title'),
        value: 'title',
        sortable: true,
      },
      {
        text: root.$t('administration.notes.table.col.title.type'),
        value: 'type',
        sortable: true,
      },
      {
        text: root.$t('administration.notes.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof NoteOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'content',
      'parent',
    ]

    const { debouncedCb, vuetifyTableOptions, paginationParams } = useFilter([], init)
    setSort(vuetifyTableOptions, { by: 'title', desc: false })

    const { exec: getNotes, data: notes, isLoading: isLoadingNotes, paginationResponse } = useGetNotes()

    const totalNotes = computed(() => paginationResponse.value.totalElements)

    async function init(): Promise<void> {
      await getNotes({ params: { ...paginationParams.value } })
    }

    const isRowInfoDialogOpen = ref(false)

    const activeNote = ref<NoteOutput | null>(null)

    function onClickInfo(note: NoteOutput): void {
      activeNote.value = { ...note }

      isRowInfoDialogOpen.value = true
    }

    const isAddEditModalOpen = ref(false)

    const isEditMode = ref(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onClickEdit([_, { item: note }]: [void, { item: NoteOutput }]): void {
      if (hasSufficientRights(Rights.NOTE_UPDATE)) {
        isEditMode.value = true

        isAddEditModalOpen.value = true

        activeNote.value = { ...note }
      }
    }

    const isDeleteDialogOpen = ref(false)

    function onClickDelete(note: NoteOutput): void {
      activeNote.value = { ...note }

      isDeleteDialogOpen.value = true
    }

    const { deleteNote } = useDeleteNote()

    async function onDelete(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteNote(activeNote.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false

      activeNote.value = null

      init()
    }

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddEditModalOpen.value = false

      isDeleteDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isEditMode.value = false

      activeNote.value = null
    }

    return reactive({
      icons: {
        mdiPlus,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      },
      state: {
        notes,
        isLoadingNotes,
        totalNotes,

        isAddEditModalOpen,
        isEditMode,

        isRowInfoDialogOpen,
        activeNote,

        isDeleteDialogOpen,

        vuetifyTableOptions,
      },
      listeners: {
        onClickInfo,

        onClickEdit,

        onClickDelete,
        onDelete,

        onCloseAddEditDeleteInfoDialog,
      },
      functions: {
        getCorrespondingEntity,

        debouncedCb,

        hasSufficientRights,
      },
    })
  },
})
