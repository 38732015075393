var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.notes,
      "loading": _vm.state.isLoadingNotes,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalNotes
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": function dblclickRow() {
        var arguments$1 = arguments;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments$1[_key];
        }

        return _vm.listeners.onClickEdit(args);
      },
      "click:info": function clickInfo($event) {
        return _vm.listeners.onClickInfo($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onClickDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pb-3 ma-0",
          attrs: {
            "align": "center"
          }
        }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_CREATE) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditModalOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('administration.notes.actions.addNote')) + " ")], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "entity",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.functions.getCorrespondingEntity('name', item)) + " ")];
      }
    }, {
      key: "entityId",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.functions.getCorrespondingEntity('id', item)) + " ")];
      }
    }, {
      key: "type",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.type) + " ")];
      }
    }], null, true)
  }, [_vm.state.isRowInfoDialogOpen ? _c('CommonInfoDialog', {
    attrs: {
      "entity": _vm.state.activeNote,
      "properties-to-show": _vm.constants.PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      "translation-key": _vm.$route.meta.i18nKey,
      "custom-parent-key": "administration"
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isRowInfoDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isRowInfoDialogOpen", $$v);
      },
      expression: "state.isRowInfoDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditModalOpen ? _c('AddEditNoteDialog', {
    attrs: {
      "entity-name": _vm.functions.getCorrespondingEntity('name', _vm.state.activeNote) || '',
      "entity-id": _vm.functions.getCorrespondingEntity('id', _vm.state.activeNote) || 0,
      "is-edit-mode": _vm.state.isEditMode,
      "note-to-edit": _vm.state.activeNote
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditModalOpen", $$v);
      },
      expression: "state.isAddEditModalOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDelete();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('administration.notes.delete.text')) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }